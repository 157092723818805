/* Layouts */

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// Sidebar Mini
.sidebar-mini {
    @media (min-width: 992px) {
        .page-body-wrapper {
            width: calc(100% - #{$sidebar-width-mini});
            .navbar {
                &.navbar-mini {
                    &.fixed-top {
                        left: $sidebar-width-mini;
                    }
                }
            }
        }
        .sidebar {
            width: $sidebar-width-mini;
            .nav {
                .sidebar-category {
                    text-align: center;
                }
                .nav-item {
                    padding: 0;
                    .nav-link {
                        @include display-flex;
                        @include align-items(center);
                        @include justify-content(center);
                        @include flex-direction(column);
                        text-align: center;
                        position: relative;
                        border-bottom: none;
                        .menu-title {
                            display: block;
                            margin: auto;
                        }
                        .badge {
                            margin-left: 5px;
                            display: none;
                        }
                        i {
                            &.menu-icon {
                                margin-right: auto;
                                margin-left: auto;
                                margin-bottom: 0.625rem;
                            }
                            &.menu-arrow {
                                display: inline-block;
                                margin-left: 5px;
                                position: absolute;
                                top: 50%;
                                right: 20px;
                                @include transform(translateY(-50%));
                            }
                        }
                    }
                }
            }
        }

        //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
        .navbar {
            left: $sidebar-width-mini;
            .navbar-menu-wrapper {
                width: calc(100% - #{$sidebar-width-mini});
            }
        }
        .sidebar {
            .nav {
                &.sub-menu {
                    padding: 0;
                    border-top: none;
                    .nav-item {
                        &::before {
                            left: 1.562rem;
                        }
                        .nav-link {
                            padding: 0.75rem 0 0.75rem 0.5rem;
                            margin-left: auto;
                            margin-right: auto;
                            display: block;
                            text-align: left;
                            width: 66%;
                        }
                    }
                }
            }
        }
    }
}

// Hidden Sidebar
.sidebar-hidden {
    @media (min-width: 992px) {
        .navbar {
            left: 0;
            .navbar-menu-wrapper {
                width: 100%;
            }
        }
        .sidebar {
            width: 0;
            .sidebar-brand-wrapper {
                width: $sidebar-width-icon;
            }
        }
        .page-body-wrapper {
            width: 100%;
        }
        .main-panel {
            width: 100%;
        }
    }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
    @media (min-width: 992px) {
        .page-body-wrapper {
            position: relative;
            width: 100%;
            .sidebar {
                transition: none;
                height: 100%;
            }
        }

        &:not(.sidebar-hidden) {
            .sidebar {
                position: absolute;
            }
            .page-body-wrapper {
                width: 100%;
            }
        }

        .main-panel {
            width: 100%;
            transition: none;
        }
    }
}

//Fixed sidebar
.sidebar-fixed {
    @media (min-width: 992px) {
        .sidebar {
            position: fixed;
            max-height: auto;

            .nav {
                max-height: calc(100vh - #{$navbar-height});
                overflow: auto;
                position: relative;

                &.sub-menu {
                    max-height: none;
                }
            }
        }

        .page-body-wrapper {
            width: 100%;
            margin-left: $sidebar-width-lg;
        }
    }
}

//Boxed layout
.boxed-layout {
    @media (min-width: 992px) {
        .container-scroller {
            background: $boxed-layout-bg;
            padding: 0 calc((100% - #{$boxed-container-width}) / 2);
        }
        .navbar {
            &.fixed-top {
                @media (min-width: 1200px) {
                    margin: 0 calc((100% - #{$boxed-container-width}) / 2);
                    max-width: $boxed-container-width;
                }
            }
        }
    }
}

//RTL layout
.rtl {
    direction: rtl;
    text-align: right;

    .sidebar {
        .nav {
            padding-right: 0;
            .nav-item {
                .nav-link {
                    text-align: middle;
                }
            }
        }
    }
    .page-body-wrapper {
        transition: all $action-transition-duration
            $action-transition-timing-function;
        -webkit-transition: all $action-transition-duration
            $action-transition-timing-function;
        -moz-transition: all $action-transition-duration
            $action-transition-timing-function;
        -ms-transition: all $action-transition-duration
            $action-transition-timing-function;
    }
    .navbar {
        transition: all $action-transition-duration
            $action-transition-timing-function;
        -webkit-transition: all $action-transition-duration
            $action-transition-timing-function;
        -moz-transition: all $action-transition-duration
            $action-transition-timing-function;
        -ms-transition: all $action-transition-duration
            $action-transition-timing-function;
        right: $sidebar-width-lg;
        @media (max-width: 900px) {
            right: 0;
        }
    }
    .product-chart-wrapper,
    .settings-panel .tab-content .tab-pane .scroll-wrapper,
    .sidebar-fixed .nav,
    .table-responsive,
    ul.chats {
        &::-webkit-scrollbar {
            width: 0.5em;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }
}
