/* Forms */

.form-group {
    margin-bottom: 1.5rem;
}

.input-group-append,
.input-group-prepend {
    color: $input-placeholder-color;
    width: auto;
    border: none;
    .input-group-text {
        border-color: $border-color;
        padding: 0.94rem 0.5rem 0.94rem 1rem;
        color: $input-placeholder-color;
    }
    button {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }
}

.custom-control {
    .custom-control-label {
        line-height: 1.6;
        margin-bottom: 0;
    }
}

.image-container {
    /* Define the max width and height of the container */
    max-width: 50px; /* Change to your desired max width */
    max-height: 50px; /* Change to your desired max height */
    width: 100%;
    height: 100%;
    overflow: hidden; /* Hide any overflowed content */
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.form-control {
    &[type="file"] {
        height: auto;
    }
    border: 1px solid $border-color;
    font-family: $type1;
    font-size: $input-font-size;
    font-weight: 400;
    padding: 8px 12px;
}

select {
    &.form-control {
        padding: 0.4375rem 0.75rem;
        border: 0;
        outline: 1px solid $border-color;
        color: #495057;
        &:focus {
            outline: 1px solid $border-color;
        }
        &:disabled {
            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari and Chrome */
            appearance: none;
        }
        @each $color, $value in $theme-colors {
            &.border-#{$color} {
                outline: 1px solid $value;
                &:focus {
                    outline: 1px solid $value;
                }
            }
        }
    }
}

.form-group {
    label {
        font-size: $default-font-size;
        line-height: 1;
        vertical-align: top;
        margin-bottom: 0.5rem;
        font-weight: bold;
    }
    &.has-danger {
        .form-control {
            border-color: theme-color(danger);
        }
    }
    .file-upload-default {
        visibility: hidden;
        position: absolute;
    }
    .file-upload-info {
        background: transparent;
    }
}
.form-check-input {
    margin-left: 0;
}

.dropzone {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    input {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }
}
