/* Tables */

.table {
    tr {
        &.no-docs {
            background: rgba(50, 50, 50, 0.1);
        }
    }
    margin-bottom: 0;
    thead {
        th {
            border-top: 0;
            border-bottom-width: 1px;
            font-family: $type1;
            font-weight: 500;
            i {
                margin-left: 0.325rem;
            }
        }
    }
    th,
    td {
        vertical-align: middle;
        font-size: $default-font-size;
        line-height: 1;
        white-space: nowrap;
        padding: 12px 15px;
        img {
            width: 36px;
            height: 36px;
            border-radius: 100%;
        }
        .badge {
            margin-bottom: 0;
        }
    }
    &.table-borderless {
        border: none;
        tr,
        td,
        th {
            border: none;
        }
    }
    &.table-striped {
        tbody {
            tr {
                &:nth-child(odd) {
                    background: $table-accent-bg;
                }

                &:nth-child(even) {
                    background: transparent;
                }
            }
        }
    }
}

.table-responsive {
    .table.table-bordered {
        border: 1px solid #dee2e6;
    }
}
