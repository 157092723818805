.tile.btn {
    .tile-title {
        margin-bottom: 1.4rem;
        i {
            color: #030035;
            font-size: 2.4rem;
        }
    }
    p {
        text-align: left;
        margin-bottom: 0;
    }
}

.count-badge {
    position: absolute;
    right: 25px;
    top: calc(50% - 10px);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    font-size: 0.8rem;
    color: #ffffff;
    background: #fa7469;
    border: 2px solid #c54a3f;
    padding: 0px 2px 4px 2px;
    text-align: center;
}

.card.tile-card:hover {
    transform: scale(1.02) translate(-1px, -1px);
    transition: all 0.05s ease-in-out;
}

button.tile.btn {
    box-shadow: 2px 2px rgba(121, 121, 121, 0.17);
}

button.tile.btn:hover {
    box-shadow: 3px 4px rgba(121, 121, 121, 0.17);
}

button.tile.no-doc {
    opacity: 0.75;

    background: rgba(0, 0, 0, 0.2);
}
